import React from "react";
import styled, { useTheme } from "styled-components";
import { OutboundLink } from "gatsby-plugin-amplitude-analytics";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ExternalLink = styled(OutboundLink)`
  text-decoration: none;
  margin-left: 1.5rem;
  :first-of-type {
    margin-left: 0px;
  }
  &:hover {
    cursor: pointer;
    path {
      transition: fill 200ms;
      fill: ${(props) => props.theme.colors.accent.primary[500]};
    }
  }
`;
const SocialLinks = ({ size }) => {
  const theme = useTheme();

  return (
    <Container>
      {/* <ExternalLink
        href="https://twitter.com/_anothercoder"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        <svg width={size} viewBox="0 0 512 512">
          <path
            d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
            fill={theme.colors.accent.primary[300]}
          />
        </svg>
      </ExternalLink> */}
      <ExternalLink
        href="https://www.linkedin.com/in/devanshi-goradia-a2bba0153/"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        <svg
          viewBox="0 0 512 512"
          width={size}
          height={size}
          fill={theme.colors.accent.primary[300]}
        >
          <path d="M475.074 0H36.926C16.53 0 0 16.531 0 36.926v438.148C0 495.47 16.531 512 36.926 512h438.148C495.47 512 512 495.469 512 475.074V36.926C512 16.53 495.469 0 475.074 0zM181.61 387h-62.347V199.426h62.347zm-31.172-213.188h-.406c-20.922 0-34.453-14.402-34.453-32.402 0-18.406 13.945-32.41 35.274-32.41 21.328 0 34.453 14.004 34.859 32.41 0 18-13.531 32.403-35.274 32.403zM406.423 387h-62.34V286.652c0-25.218-9.027-42.418-31.586-42.418-17.223 0-27.48 11.602-31.988 22.801-1.649 4.008-2.051 9.61-2.051 15.215V387h-62.344s.817-169.977 0-187.574h62.344v26.558c8.285-12.78 23.11-30.96 56.188-30.96 41.02 0 71.777 26.808 71.777 84.421zm0 0" />
        </svg>
      </ExternalLink>
      <ExternalLink
        href="https://www.practo.com/"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        <svg
          width={size}
          height={size}
          viewBox="0 0 512 290"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M455.809 71.5938C480.267 65.2622 507.201 83.7857 511.196 108.435C514.203 123.454 508.587 139.341 497.471 149.722C479.043 170.292 442.556 164.947 427.799 142.64C415.548 125.456 417.435 99.806 432.384 84.8017C438.487 77.9695 447.097 73.9645 455.809 71.5938Z"
            fill={theme.colors.accent.primary[300]}
          />
          <path
            d="M237.532 61.5668C223.333 69.0756 208.239 81.5002 207.115 99.1107C205.202 113.912 206.852 130.118 215.979 142.309C227.616 158.389 248.906 164.367 267.598 160.784C279.322 158.605 288.414 149.692 295.891 140.796C307.422 126.877 307.141 106.529 302.42 89.8913C293.978 63.3314 261.753 52.3654 237.532 61.5668ZM241.165 3.26111C263.315 -3.25731 287.589 0.362037 308.019 10.8599C336.663 27.6422 357.742 58.1275 362.042 91.908C362.867 105.413 363.201 119.134 361.323 132.585C358.409 146.828 353.425 160.712 346.299 173.28C327.589 201.065 295.803 220.422 262.526 220.26C242.938 220.35 226.352 208.898 209.064 201.245C208.011 230.614 208.642 260.037 208.748 289.441C188.932 288.847 169.134 289.423 149.318 289.081C148.371 195.032 149.459 100.983 148.757 6.93448C167.238 6.80843 185.703 6.93448 204.184 6.79042C204.588 11.5802 205.009 16.334 205.501 21.1237C217.05 14.5333 228.089 6.44829 241.165 3.26111Z"
            fill={theme.colors.accent.primary[300]}
          />
          <path
            d="M36.0617 72.0795C60.5191 65.7479 87.4531 84.2714 91.4483 108.92C94.4557 123.939 88.8389 139.827 77.7233 150.208C59.2955 170.778 22.8085 165.433 8.0515 143.125C-4.19929 125.942 -2.31228 100.292 12.6363 85.2874C18.7396 78.4552 27.3491 74.4501 36.0617 72.0795Z"
            fill={theme.colors.accent.primary[300]}
          />
        </svg>
      </ExternalLink>
    </Container>
  );
};

export default SocialLinks;
