import React from "react"
import styled, { useTheme } from "styled-components"
import Text from "components/common/Text"
import { Event, track } from "../../analytics"

const StyledText = styled(Text)`
  transition: all 300ms ease-in-out;
  &:hover {
    color: ${props => props.theme.colors.accent.primary[500]};
    text-decoration: underline;
  }
`

const LinkText = props => {
  const theme = useTheme()
  return (
    <StyledText
      as="a"
      onClick={() =>
        track(Event.outbound_link_clicked, {
          href: props.href ? props.href : undefined,
        })
      }
      target="_blank"
      className="link"
      rel="noreferrer"
      size="small"
      lineHeight="medium"
      weight="normal"
      display="inline"
      color={theme.colors.accent.primary[400]}
      {...props}
    />
  )
}

export default LinkText
