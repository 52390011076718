const track = (eventName, eventProperties = {}) => {
  window.amplitude.getInstance().logEvent(eventName, eventProperties)
}

const Event = {
  navigation_tab_clicked: "Navigation Tab Clicked",
  avatar_navigation_clicked: "Avatar Navigation Clicked",
  back_clicked: "Back Clicked",
  theme_toggled: "Theme Toggled",
  conversation_topic_clicked: "Conversation Topic Clicked",
  skill_circle_clicked: "Skill Circle Clicked",
  talk_item_clicked: "Talk Item Clicked",
  outbound_link_clicked: "Outbound Link Clicked",
}

export { track, Event }
