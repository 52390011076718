import React from "react"
import { Helmet } from "react-helmet"
import { useTheme } from "styled-components"

const Metadata = () => {
  const theme = useTheme()

  return (
    <Helmet>
      <html lang="en" />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"
        rel="stylesheet"
      />
      <meta name="theme-color" content={theme.colors.background.primary[500]} />
    </Helmet>
  )
}

export default Metadata
