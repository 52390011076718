import H1 from "components/markdown/H1"
import H2 from "components/markdown/H2"
import H3 from "components/markdown/H3"
import P from "components/markdown/P"
import Strong from "components/markdown/Strong"
import A from "components/markdown/A"

const MdxComponents = {
  h1: H1,
  h2: H2,
  h3: H3,
  p: P,
  strong: Strong,
  a: A,
  ul: P,
}

export default MdxComponents
