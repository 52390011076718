const fonts = {
  size: {
    xsmall: "14px",
    small: "18px",
    medium: "20px",
    large: "24px",
    xlarge: "28px",
  },
  weight: {
    light: "300",
    regular: "400",
    bold: "700",
  },
  letterSpacing: {
    small: "0.5px",
    medium: "1px",
    large: "1.5px",
  },
  lineHeight: {
    small: "1.5",
    medium: "2",
    large: "3",
  },
};

const lightTheme = {
  colors: {
    text: {
      primary: {
        500: "#494C6E",
      },
      secondary: {
        500: "rgb(0, 173, 147)",
      },
      tertiary: {
        500: "rgb(255,255,255)",
      },
    },
    background: {
      primary: {
        500: "#FBFBFB",
        300: "rgba(251,251,251,0.6)",
        400: "rgba(251,251,251,0.8)",
      },
    },
    accent: {
      primary: {
        50: "rgba(0, 173, 147, 0.1)",
        100: "rgba(0, 173, 147, 0.2)",
        200: "rgba(0, 173, 147, 0.4)",
        300: "rgba(0, 173, 147, 0.6)",
        400: "rgba(0, 173, 147, 0.8)",
        500: "rgba(0, 173, 147, 1)",
      },
    },
  },
  fonts: {
    ...fonts,
  },
};

const darkTheme = {
  colors: {
    text: {
      primary: {
        500: "#fefeff",
      },
      secondary: {
        500: "rgba(34,252,208,1)",
      },
    },
    background: {
      primary: {
        500: "#161729",
        300: "rgba(22, 23, 41,0.6)",
        400: "rgba(22, 23, 41,0.8)",
      },
    },
    accent: {
      primary: {
        50: "rgba(34,252,208,0.1)",
        100: "rgba(34,252,208,0.2)",
        200: "rgba(34,252,208,0.4)",
        300: "rgba(34,252,208,0.6)",
        400: "rgba(34,252,208,0.8)",
        500: "rgba(34,252,208,1)",
      },
    },
  },
  fonts: {
    ...fonts,
  },
};

export { darkTheme, lightTheme };
