import React from "react"
import styled from "styled-components"

const Space = styled(({ margin, padding, children, ...props }) => {
  if (React.Children.toArray(children).length !== 1) {
    throw new Error("Expected a single child for Space component")
  }
  return React.cloneElement(children, props)
})`
  &&& {
    ${props => (props.margin ? `margin: ${props.margin}` : "")};
    ${props => (props.padding ? `padding: ${props.padding}` : "")};
  }
`

export default Space
