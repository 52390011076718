import React from "react";
import Header from "components/home/Header";
import { ThemeProvider } from "styled-components";
import { MDXProvider } from "@mdx-js/react";
import { darkTheme, lightTheme } from "constants/theme";
import GlobalStyle from "components/layout/GlobalStyle";
import { useThemeSwitcher } from "hooks/useThemeSwitcher";
import Metadata from "components/layout/Metadata";
import MdxComponents from "components/layout/MdxComponents";
import Footer from "components/layout/Footer";
import SEO from "components/layout/SEO";

export default function Base({ children, pageContext }) {
  const [currentTheme, toggleTheme] = useThemeSwitcher();
  return (
    <ThemeProvider theme={currentTheme === "dark" ? darkTheme : lightTheme}>
      <MDXProvider components={MdxComponents}>
        <Metadata />
        <SEO />
        <GlobalStyle />
        <Header
          toggleTheme={toggleTheme}
          currentTheme={currentTheme}
          showBackButton={pageContext?.showBackButton}
          backTo={pageContext?.backTo}
        />
        {children}
        {/* <footer>
          <Footer />
        </footer> */}
      </MDXProvider>
    </ThemeProvider>
  );
}
