import styled from "styled-components";

const Text = styled.div`
  font-size: ${(props) => props.theme.fonts.size[props.size]};
  font-weight: ${(props) => props.theme.fonts.weight[props.weight]};
  color: ${(props) => props.color || props.theme.colors.text.primary[500]};
  display: ${(props) => props.display};
  text-decoration: ${(props) => props.textDecoration};
  text-align: ${(props) => props.textAlign};
  letter-spacing: ${(props) =>
    props.theme.fonts.letterSpacing[props.letterSpacing]};
  line-height: ${(props) =>
    props.lineHeight ? props.theme.fonts.lineHeight[props.lineHeight] : "auto"};
`;
Text.defaultProps = {
  size: "medium",
  weight: "regular",
  display: "block",
  letterSpacing: "medium",
  textDecoration: "none",
  as: "div",
};

export default Text;
