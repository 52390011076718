import React from "react"
import Text from "components/common/Text"
import styled, { useTheme } from "styled-components"
import LinkText from "components/common/LinkText"

const A = props => {
  const theme = useTheme()
  return (
    <LinkText
      size="small"
      lineHeight="medium"
      weight="normal"
      display="inline"
      textDecoration="underline"
      color={theme.colors.accent.primary[400]}
      {...props}
    />
  )
}

export default A
