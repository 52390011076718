import React from "react"
import styled from "styled-components"
import Text from "components/common/Text"
import Space from "components/common/Space"
import SocialLinks from "../common/SocialLinks"

const Container = styled.div`
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
`

const StyledText = styled(Text)`
  border-top: ${props => `2px solid ${props.theme.colors.accent.primary[300]}`};
  padding-top: 0.5rem;
`

const Footer = () => {
  return (
    <Container>
      <Space margin="0 0 1rem 0">
        <StyledText size="xsmall" weight="light">
          Get in touch
        </StyledText>
      </Space>
      <SocialLinks size="20px" />
    </Container>
  )
}

export default Footer
