module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Devanshi Goradia","short_name":"Devanshi","start_url":"/","background_color":"#212231","theme_color":"#212231","display":"standalone","theme_color_in_head":false,"icon":"static/icon.png","icon_options":{"purpose":"maskable"},"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"227aa97ec455641edd8b8c8bdb7a9bc5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embedder","options":{"customTransformers":[],"services":{}}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/vercel/path0/src/components/layout/Base.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":false,"respectDNT":false,"exclude":[],"eventTypes":{"outboundLinkClick":"Outbound Link Clicked","pageView":"Page Visited"},"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true},"environments":["production","development"]},
    }]
