import React from "react"
import Text from "components/common/Text"
import { useTheme } from "styled-components"

const Strong = props => {
  const theme = useTheme()
  return (
    <Text
      size="small"
      lineHeight="medium"
      weight="normal"
      display="inline"
      color={theme.colors.accent.primary[500]}
      {...props}
    />
  )
}

export default Strong
