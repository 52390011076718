import React from "react"
import Text from "components/common/Text"
import Space from "components/common/Space"
import { useTheme } from "styled-components"

const H3 = props => {
  const theme = useTheme()
  return (
    <Space padding="1rem 0px 4px 0px">
      <Text
        size="medium"
        weight="bold"
        lineHeight="medium"
        color={theme.colors.accent.primary[500]}
        {...props}
      />
    </Space>
  )
}

export default H3
